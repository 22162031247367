<template>
  <Alert
    variant="danger"
    dismissible
    :show="getAlertErrors.showCountDown"
    @dismissed="dismissed"
    @dismiss-count-down="countDownChanged"
  >
    <!-- <strong>Error:</strong> {{ getAlertErrors.errorCode }}: -->
    {{ getAlertErrors.descreption }}
    <q-btn
      unelevated
      no-caps
      rounded
      color="tertiary"
      class="q-ml-sm no-hover"
      size="12px"
      padding="4px 15px"
      label="Book Time slot"
      @click="
        changeDialogState({
          dialog: 'reserveTimeslot',
          val: true,
        })
      "
    />
  </Alert>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('cart', ['getAlertErrors']),
  },
  methods: {
    dismissed() {
      this.$store.commit('cart/COUNT_DOWN_CHANGE', 0)
    },
    countDownChanged(dismissCountDown) {
      this.$store.commit('cart/COUNT_DOWN_CHANGE', dismissCountDown)
    },
  },
  destroyed() {
    this.$store.commit('cart/COUNT_DOWN_CHANGE', 0)
  },
}
</script>
